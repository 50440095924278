@import url('https://fonts.googleapis.com/css?family=Inconsolata|Roboto+Mono|Special+Elite');
.App {
  width: 100vw;
  overflow: hidden;
  color: white;
}
.fish {
  height: 50px;
  width: 50px;
  position: absolute;
  transition: initial;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(1,1,0,0);
}
.fish img {
  width: 100%;
}
h1 {
  font-size: 50px;
  margin-left: 20px;
  margin-top: 0px;
  margin-bottom: 100px;
  letter-spacing: 0.05em;
  color: white;
}
main {
  color: white;
  background: #1500ff;
  height: 85vh;
  margin: 0;
  padding-left: 0;
  text-align: left;
  font-family: 'Roboto Mono', monospace, sans-serif;
  display: flex;
  overflow: scroll;
}
footer {
  height: 5vh;
  background: #0400ee;
  padding-top: 4px;
  position: sticky;
}
h4 {
  margin-top: 0px;
  text-align: center;
}
.nav-icon {
  width: 60px;
  margin: 0;
  margin-top: -20px;
  padding: 0;
}
.social-links {
  height: 8vh;
  background: #0400ee;
  text-align: right;
  padding-top: 2vh;
  display: flex;
  justify-content: space-between;
}
.logo {
  width: 35px;
  margin: 2px 15px 0px 15px;
}
.displayOn {
  margin: 0;
  height: 85vh;
  width: 25%;
  background: #0400ee;
  transition-duration: 0.5s;
}
nav {
  height: auto;
  z-index: 1;
  overflow: hidden;
}
.displayOff {
  margin: 0;
  height: 85vh;
  width: 0%;
  background: #0400ee;
  transition-duration: 0.5s;
}
.contactlink {
  text-align: left;
}
.contactlink h1 {
  margin: 20px;
  font-size: 30px;
}
.contactlink h1 span{
  margin: 20px;
  font-size: 30px;
}
.contactlink h1 span:hover{
  text-decoration: underline;
}
.headshot {
  width: 55px;
  height: 50px;
  border: 3px solid white;
  border-radius: 100%;
  margin-top: 25px;
}
.headshot-container {
  display: flex;
  justify-content: center;
}
.reel {
  position: relative;
  height: 320px;
  background: rgba(255,255,255,.2);
  width: 100%;
  margin: 0;
  padding: 5px 0;
  overflow: hidden;
}
.reelElement {
  position: absolute;
  color: white;
  font-size: 8px;
  height: 13vh;
  max-width: 138px;
  width: 15%;
  background: #1500ff;
  border-radius: 10%;
  border: 2px solid white;
  margin: 0;
  padding: 5px;
  transition-property: initial;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(1,1,0,0);
  overflow: hidden;
}
.reelElement:hover {
  height: auto;
}
.mainView {
  width: 100%;
  height: auto;
  overflow: scroll;
}
.name-header {
  font-size: 45px;
  margin: 0;
  margin-top: 20px;
  margin-left: 13px;
  padding: 0;
}
.title-header {
  font-size: 30px;
  margin-top: 20px;
  margin-left: 13px;
  margin-bottom: 20px;
  padding: 0;
}
.tech-box {
  display: flex;
  color: black;
  flex-wrap: wrap;
}
h3 {
  color: black;
}
p {
  color: white;
  text-align: center;
  margin: 0;
  padding: 0;
}
h2 {
  text-align: center;
  margin: 0;
  padding: 0;
  letter-spacing: .07em;
  margin-bottom: 10px;
  margin-top: 40px;
  font-size: 18.5px;
}
li {
  margin-left: 0px;
  line-height: 30px;
  list-style-type: hebrew;
}
a {
  color: white;
  text-decoration: none;
}
.about-text {
  text-align: left;
  font-size: 30px;
  padding: 15px;
}
span {
  color: white;
  font-size: 12px;
}
.Javascript {
  width: 60px;
  height: 20px;
  margin: 2px;
  background: red;
  color: black;
}
.HTML {
  width: 30px;
  height: 20px;
  margin: 2px;
  background: brown;
  color: black;
}
.CSS {
  width: 30px;
  height: 20px;
  margin: 2px;
  background: magenta;
  color: black;
}
.React {
  width: 30px;
  height: 20px;
  margin: 2px;
  background: cyan;
  color: black;
}
.SVG {
  width: 30px;
  height: 20px;
  margin: 2px;
  background: green;
  color: black;
}
.JQuery {
  width: 40px;
  height: 20px;
  margin: 2px;
  background: purple;
  color: black;
}
.Express {
  width: 50px;
  height: 20px;
  margin: 2px;
  background: brown;
  color: black;
}
.API {
  width: 30px;
  height: 20px;
  margin: 2px;
  background: teal;
  color: black;
}
.reelImage {
  width: 85%;
  height: auto;
  margin-bottom: 15px;
}
.projectContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-bottom: 2px solid grey;
  padding: 15px;
}
.projectDetails {
  width: 70%;
  margin-left: 5px;
}
.imageContainer {
  width: 250px;
  margin-left: 20px;
  margin-right: 20px;
}
.technologies {
  display: flex;
  margin-bottom: 20px;
}
.technologies > .API {
  width: 30px;
  height: 20px;
  margin: 6px;
}
.technologies > .React {
  width: 50px;
  height: 20px;
  margin: 6px;
}
.technologies > .Express {
  width: 70px;
  height: 20px;
  margin: 6px;
}
.technologies > .JQuery {
  width: 40px;
  height: 20px;
  margin: 6px;
}
.technologies > .SVG {
  width: 30px;
  height: 20px;
  margin: 6px;
}
.technologies > .CSS {
  width: 30px;
  height: 20px;
  margin: 6px;
}
.technologies > .HTML {
  width: 40px;
  height: 20px;
  margin: 6px;
}
.technologies > .Javascript  {
  width: 100px;
  height: 20px;
  margin: 6px;
}
.projectImage {
  width: 250px;
  height: auto;
}
.projectTitle {
  color: white;
  text-decoration: underline;
  letter-spacing: 0.11em;
  margin-top: 0;
}
.highlightedProjects {
  display: block;
}
.projectPageHeader {
  margin-bottom: 30px;
}
.description {
  margin-top: 0;
  text-align: left;
}
h4 > span {
  font-size: 16px;
}
b > a {
  margin-left: 0px;
  margin-right: 30px;
  text-decoration: underline;
}
@media only screen and (max-width: 883px) {
  .reelElement {
    height: 10vh;
  }
  .contactlink h1 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 704px) {
  .reelElement {
    width: 20%;
  }
  .about-text {
    font-size: 26px;
  }
}
@media only screen and (max-width: 690px) {
  .projectPageHeader {
    font-size: 32px;
  }
}
@media only screen and (max-width: 612px) {
  .projectContainer {
    flex-direction: column-reverse;
  }
  .projectDetails {
    width: 100%;
    margin-bottom: 20px;
  }
  .projectImage {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 602px) {
  .name-header {
    font-size: 38px;
    margin: 0;
    margin-top: 17px;
    margin-left: 13px;
    padding: 0;
  }
  .title-header {
    font-size: 26px;
    margin-top: 17px;
    margin-left: 13px;
    margin-bottom: 17px;
    padding: 0;
  }
  h2 {
    text-align: center;
    margin: 0;
    padding: 0;
    letter-spacing: .07em;
    margin-bottom: 20px;
    margin-top: 40px;
    font-size: 16px;
  }
  span {
    font-size: 10px;
  }
  .about-text {
    font-size: 22px;
  }
}
@media only screen and (max-width: 560px) {
  li {
    font-size: 12px;
  }
  footer {
    font-size: 8px;
  }
  p {
    font-size: 10px;
  }
}
@media only screen and (max-width: 475px) {
  ul {
    margin-left: -10px;
  }
  .about-text {
    font-size: 20px;
  }
}
@media only screen and (max-width: 430px) {
  ul {
    margin-left: -15px;
  }
}
@media only screen and (max-width: 450px) {
  .name-header {
    font-size: 31.5px;
    margin: 0;
    margin-top: 17px;
    margin-left: 13px;
    padding: 0;
  }
  .title-header {
    font-size: 21.6px;
    margin-top: 17px;
    margin-left: 13px;
    margin-bottom: 17px;
    padding: 0;
  }
  p {
    font-size: 8px;
  }
  .displayOn {
    margin: 0;
    height: 85vh;
    width: 35%;
    background: #0400ee;
    transition-duration: 0.5s;
  }
}
